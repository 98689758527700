









































import Vue from 'vue'
import { Component } from 'vue-property-decorator';
import { RobotOption, TwitterCardOption } from '@/models/MetaTags';
import { getLocalesString } from '@/models/Locale';

@Component({
    metaInfo() {
        return {
            title: this.$i18n.t("imprint.title").toString(),
            meta: [
                // Search Engines:
                { name: 'title', content: this.$i18n.t("imprint.metaTags.title").toString() },
                { name: 'description', content: this.$i18n.t("imprint.metaTags.description").toString() },
                { name: 'keywords', content: this.$i18n.t("metaTags.keywords").toString() },
                { name: 'author', content: this.$i18n.t("metaTags.author").toString() },
                { name: 'language', content: this.$i18n.locale },
                { name: 'robots', content: [RobotOption.All].join(',') },

                // Open Graph: Facebook, Instagram, WhatsApp, LinkedIn, Xing, Twitter:
                { property: 'og:type', content: "website" },
                { property: 'og:title', content: this.$i18n.t("imprint.metaTags.title").toString() },
                { property: 'og:description', content: this.$i18n.t("imprint.metaTags.description").toString() },
                { property: 'og:image', content: `${window.location.origin}${require('@/assets/logos/braendz-logo-tm-blue.png')}` },
                { property: 'og:locale', content: this.$i18n.locale },
                { property: 'og:locale:alternate', content: getLocalesString(',') },
                { property: 'og:site_name', content: this.$i18n.t("metaTags.title").toString()},

                // Twitter:
                { property: 'twitter:card', content: TwitterCardOption.SummaryLargeImage },
                { property: 'twitter:site', content: `@${process.env.VUE_APP_TWITTER_ACCOUNT}` }
            ]
        };
    }
})
export default class Imprint extends Vue {
    // Members:
    
    // Properties:
    
    // Getter:

    // Lifecycle Methods:

    // Methods:
}
